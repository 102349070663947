// const server = 'http://localhost:3000/';
const server =  process.env.REACT_APP_API_URL;

const apiLink = server + 'api/';
const imagesUrl = server + 'uploads/products/';

// deliverymen
const loginApi = apiLink + 'deliverymen/login';
const deliverymenDataApi = apiLink + 'deliverymen/deliverymenData';

// orders
const getOrdersApi = apiLink + 'orders/get-orders';
const getOrderDetailsApi = apiLink + 'orders/get-orderDetails';
const updateOrderStatusApi = apiLink + 'orders/updateOrderStatus';

export {
    imagesUrl,
    // deliverymen
    loginApi,
    deliverymenDataApi,
    // orders
    getOrdersApi,
    getOrderDetailsApi,
    updateOrderStatusApi,
}