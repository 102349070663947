import Cookies from 'js-cookie';

export const headersConfig = {
    headers: {
      'Authorization': Cookies.get('token'),
      'Content-Type': 'application/json',
    },
  };


