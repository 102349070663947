import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getOrdersApi, getOrderDetailsApi, updateOrderStatusApi } from '../../constant';
import Cookies from 'js-cookie';
import { headersConfig } from '../../config/config';




export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async () => {
        const response = await axios.post(getOrdersApi,

            { deliveryman_id: Cookies.get('id') },
            {
                headers: headersConfig.headers,
            },
        );
        return response.data;
    }
);
export const fetchOrderDetails = createAsyncThunk(
    'orders/fetchOrderDetails',
    async (order) => {
        const response = await axios.post(getOrderDetailsApi,
            order,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

export const updateOrderStatusHandle = createAsyncThunk(
    'orders/updateOrderStatusHandle',
    async (data) => {
        const response = await axios.post(updateOrderStatusApi,
            data,
            {
                headers: headersConfig.headers,
            },

        );
        return response.data;
    }
);

const ordersSlices = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        orderDetails: {},
        products: [],
        message: {},
        isLoading: false,
        error: null,
    },
    reducers: {

    },


    extraReducers: (builder) => {

        // fetch orders
        builder.addCase(fetchOrders.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrders.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.isLoading = false;
        });

        // fetch order details
        builder.addCase(fetchOrderDetails.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchOrderDetails.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(fetchOrderDetails.fulfilled, (state, action) => {
            state.orderDetails = action.payload.mapOrderDetails;
            state.products = action.payload.productDetails;
            state.isLoading = false;
        });

        // update order status
        builder.addCase(updateOrderStatusHandle.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(updateOrderStatusHandle.rejected, (state, action) => {
            state.error = action.error;
            state.isLoading = false;
        });
        builder.addCase(updateOrderStatusHandle.fulfilled, (state, action) => {
            state.orderDetails = action.payload;
            state.isLoading = false;
        });
    },

});

export default ordersSlices.reducer;
