import Cookies from 'js-cookie';
import './App.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';



const Login = React.lazy(() => import('./pages/Login'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Orders = React.lazy(() => import('./pages/Orders'));
const OrderDetails = React.lazy(() => import('./pages/OrderDetails'));

const ProtectedRoute = ({ element }) => {
  const token = Cookies.get('token');
  if (!token) {
    return <Navigate to="/login" />;
  }

  return element;
};

const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Check if the token cookie exists
    const savedToken = Cookies.get('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);
  const loading = (
    <div className="d-flex justify-content-center align-items-center mt-5">
      loading...
      {/* <CSpinner /> */}
    </div>
  );
  return (
    <Router basename="/">
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="/login"
            name="تسجيل الدخول"
            element={token ? <Navigate to="/" /> : <Login />}
          />

          <Route
            path="/"
            name="home"
            element={<ProtectedRoute element={<Orders />} />}
          />
         
          <Route
            path="/profile"
            name="profile"
            element={<ProtectedRoute element={<Profile />} />}
          />
          <Route
            path="/notifications"
            name="notifications"
            element={<ProtectedRoute element={<Notifications />} />}
          />
          <Route
            path="/orderDetails"
            name="orderDetails"
            element={<ProtectedRoute element={<OrderDetails />} />}
          />
         
          <Route
            path="*"
            name="all"
            // element={<Home />}
            element={<ProtectedRoute element={<Login />} />}
          />
        </Routes>
      </Suspense>
    </Router>
  )
};

export default App;
