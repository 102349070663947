import { configureStore } from '@reduxjs/toolkit'
import usersSlice from './slices/users-slices'
import ordersSlices from './slices/orders-slices'

export const store = configureStore({
  reducer: {
    users: usersSlice,
    orders: ordersSlices,
  },
})
